import { useEffect, useState } from 'react'
import { useAppContext, useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'
import { hoursMinsSecs } from '../util/time'
import useUserIsCurrentRoomAdmin from '../hooks/useUserIsCurrentRoomAdmin'
import useCurrentRoom from '../hooks/useCurrentRoom'
import { useMutation, useQuery } from '@apollo/client'
import PlaybackFinished from '../graphql/queries/PlaybackFinished'
import RoomQueue from '../graphql/queries/RoomQueue'
import RemoveRequest from '../graphql/queries/RemoveRequest'

const cdnUrl = process.env.REACT_APP_CDN_URL

export default function Player() {

    const appState = useAppContext()
    const { queue, playMode } = appState
    const dispatch = useAppDispatch()

    const room = useCurrentRoom()
    const userIsRoomAdmin = useUserIsCurrentRoomAdmin()

    // Play queue and now playing data
    const { data: roomQueueData, refetch: refetchQueue } = useQuery(RoomQueue, {
        fetchPolicy: 'network-only',
        returnPartialData: true,
        skip: !room,
        variables: { roomId: room?.id }
    })
    const nowPlaying = (playMode === 'local' ? queue[0] : roomQueueData?.room.queue[0])
    const track = nowPlaying?.track

    // The audio element and its datastore remain constant for all renders.  The playerData is a cheap hack that
    // allows functions called outside the render cycle to get the most up-to-date values.  They capture their
    // context early and it never gets updated, so this is the only way I could figure out to allow events from the
    // audio element to access that data.  If you know of a better (more React-specific) method of doing that feel
    // free to make it better.
    const [ audio ] = useState(new Audio())
    const [ playerData ] = useState({ room, nowPlaying, playMode, ended: false })
    playerData.room = room
    playerData.nowPlaying = nowPlaying
    playerData.playMode = playMode
    playerData.userIsRoomAdmin = userIsRoomAdmin

    const [ playbackFinishedMutation ] = useMutation(PlaybackFinished, { refetchQueries: [ RoomQueue ] })
    const [ skipMutation ] = useMutation(RemoveRequest)

    const [ playing, setPlaying ] = useState(false)
    const [ shouldPlay, setShouldPlay ] = useState(false)
    const [ hd, setHd ] = useState(true)
    const [ playHeadTime, setPlayHeadTime ] = useState('0:00')
    const [ playHeadPercent, setPlayHeadPercent ] = useState(0)
    const [ scrubKnobPosition, setScrubKnobPosition ] = useState(-10)
    audio.autoplay = shouldPlay || playMode === 'local'
    audio.preload = shouldPlay ? 'auto' : 'none'

    const [ volume, setVolume ] = useState(100)
    audio.volume = volume / 100

    useEffect(() => {
        audio.onabort = (e) => onPlayerEvent('abort', e)
        audio.oncanplay = (e) => onPlayerEvent('canPlay', e)
        audio.oncanplaythrough = (e) => onPlayerEvent('canPlayThrough', e)
        audio.ondurationchange = (e) => onPlayerEvent('durationChange', e)
        audio.onemptied = (e) => onPlayerEvent('emptied', e)
        audio.onended = (e) => onPlayerEvent('ended', e)
        audio.onerror = (e) => onPlayerEvent('error', e)
        audio.onloadeddata = (e) => onPlayerEvent('loadedData', e)
        audio.onloadedmetadata = (e) => onPlayerEvent('loadedMetaData', e)
        audio.onloadstart = (e) => onPlayerEvent('loadStart', e)
        audio.onpause = (e) => onPlayerEvent('pause', e)
        audio.onplay = (e) => onPlayerEvent('play', e)
        audio.onplaying = (e) => onPlayerEvent('playing', e)
        audio.onprogress = (e) => onPlayerEvent('progress', e)
        audio.onseeked = (e) => onPlayerEvent('seeked', e)
        audio.onseeking = (e) => onPlayerEvent('seeking', e)
        audio.onstalled = (e) => onPlayerEvent('stalled', e)
        audio.onsuspend = (e) => onPlayerEvent('suspend', e)
        audio.onwaiting = (e) => onPlayerEvent('waiting', e)
        document.ontouchmove = onScrubMove
        document.ontouchend = onScrubEnd
        document.onmousemove = onScrubMove
        document.onmouseup = onScrubEnd
        navigator.mediaSession.setActionHandler('play', () => { if (audio?.paused) togglePlay() })
        navigator.mediaSession.setActionHandler('pause', () => { if (!audio?.paused) togglePlay() })
        navigator.mediaSession.setActionHandler('stop', () => { if (!audio.paused) togglePlay() })
        navigator.mediaSession.setActionHandler('nexttrack', () => { if (playerData.userIsRoomAdmin) skip() })
    }, [])

    useEffect(() => {
        if (playerData.errorTask) {
            clearTimeout(playerData.errorTask)
            playerData.errorTask = 0
            playerData.retryCount = 0
        }
        let taskId
        console.log(`player source change: ${nowPlaying?.id} @ ${nowPlaying?.playTime} [ ${playMode} ${hd ? 'hd' : 'sd'} ]`)
        if (nowPlaying) {
            const offset = currentOffset()
            console.log(`nowPlaying changed.  playing ${track.title} by ${track.artist}: ${trackUrl(track)} @ ${offset}`)
            navigator.mediaSession.metadata = new MediaMetadata({
                artist: track.artist ?? 'Unknown Artist',
                album: track.album ?? '',
                title: track.title ?? 'Unknown Title'
            })
            audio.src = trackUrl(track)
            audio.currentTime = offset
            taskId = startUpdates()
            playerData.durationSet = false
            playerData.suspended = false
            playerData.ended = false
        } else {
            console.log(`nowPlaying changed to null`)
            audio.src = ''
            setPlayHeadPercent(0)
            setPlayHeadTime(0)
        }
        if (taskId) {
            return () => { clearInterval(taskId) }
        }
    }, [nowPlaying?.id, nowPlaying?.playTime, playMode, hd])

    function currentOffset() {
        // We want to synchronize playback using the time the server thinks this track started, but we need to
        // account for the clock difference between this system and the server as well as the connection latency.
        // To do this, we get a current timestamp from the server and measure the best-case round-trip time when
        // we're setting up the connection for GraphQL subscriptions and compute the total offset value we should
        // use.  This is stored in global.serverOffset, and tells us whether the server time is ahead of ours (positive
        // values) or behind us (negative values).
        const defaultLatency = 30 // ms
        if (playerData.nowPlaying?.playTime) {
            const offsetPlaytime = playerData.nowPlaying?.playTime - (global.serverOffset ?? defaultLatency)
            const now = Date.now()
            const actual = (now - offsetPlaytime) / 1000
            console.log(`measured playback offset: ${actual}`)
            if (actual < 3) return 0
            else return actual
        } else {
            return 0
        }
    }

    function onPlayerEvent(type) {
        console.log(`[${type}] seeking: ${audio.seeking}  readyState: ${audio.readyState}  networkState: ${audio.networkState}  duration: ${audio.duration}  currentTime: ${audio.currentTime}`)
        switch (type) {
            case 'canPlayThrough':
                playerData.durationSet = true
                if (playerData.ended && shouldPlay) {
                    playerData.ended = false
                    audio.currentTime = currentOffset()
                    audio.play().catch(error => console.error(error))
                }
                break
            // case 'durationChange':
            //     if (playerData.suspended) {
            //         playerData.durationSet = true
            //         if (playerData.ended && shouldPlay) {
            //             playerData.ended = false
            //             audio.currentTime = currentOffset()
            //             audio.play().catch(error => console.error(error))
            //         }
            //     }
            //     break
            case 'ended':
                playerData.ended = true
                break
            case 'error':
                if (!playerData.errorTask)
                    playerData.errorTask = setTimeout(restartPlayer, 5000)
                break
            case 'play':
                setPlaying(true)
                navigator.mediaSession.playbackState = 'playing'
                break
            case 'pause':
                setPlaying(false)
                navigator.mediaSession.playbackState = 'paused'
                break
            case 'suspend':
                playerData.suspended = true
                break
            default:
        }
    }

    function onScrubStart(e) {
        playerData.scrubbing = true
    }

    function onScrubMove(e) {
        if (playerData.scrubbing) {
            const x = e.touches ? e.touches[0].pageX : e.pageX
            setScrubKnobPosition(x - 10)
        }
    }

    function onScrubEnd(e) {
        if (playerData.scrubbing) {
            playerData.scrubbing = false
            const x = e.touches ? e.touches[0].pageX : e.pageX
            const percent = x / e.target.parentElement.clientWidth
            if (Number.isFinite(audio.duration)) {
                audio.currentTime = audio.duration * percent
                setPlayHeadTime(hoursMinsSecs(playerData.durationSet ? audio.currentTime : 0))
                setPlayHeadPercent(playerData.durationSet ? audio.currentTime / audio.duration : 0)
            } else {
                audio.currentTime = 0
            }
        }
    }

    async function reportPlaybackFinished() {
        if (playMode === 'local') return
        try {
            const p = playerData.nowPlaying
            console.log(`reporting track ended: ${p?.track.title} (request ${p?.id})`)
            await playbackFinishedMutation({variables: {roomId: playerData.room?.id, requestId: p?.id}})
        } catch (error) {
            dispatch(Actions.showToast(<span className="ErrorMessage">error.message</span>))
        }
    }

    function restartPlayer() {
        if (!playerData.nowPlaying || !shouldPlay) return
        playerData.retryCount += 1
        if (playerData.retryCount > 3) {
            reportPlaybackFinished()
            return
        }
        const track = playerData.nowPlaying.track
        console.log(`restarting player: error count: ${playerData.retryCount}`)
        playerData.errorTask = 0
        audio.src = ''
        audio.src = trackUrl(track)
        audio.currentTime = currentOffset()
        audio.play().catch( err => console.error(`failed to play`, err) )
    }

    async function skip() {
        if (playerData.playMode === 'room') {
            try {
                await skipMutation({ variables: { roomId: room.id, requestId: nowPlaying.id } })
            } catch (error) {
                console.error(`failed to skip`, error)
            }
        }
    }

    function startUpdates() {
        stopUpdates()
        playerData.updateTask = setInterval(updatePlaybackTime, 250)
        return playerData.updateTask
    }

    function stopUpdates() {
        clearInterval(playerData.updateTask)
    }

    async function syncPlayback() {
        try {
            if (playMode === 'local') {
                await audio.play()
            } else {
                    audio.currentTime = currentOffset()
                    await audio.play()
                    await refetchQueue()
            }
            startUpdates()
        } catch (error) {
            console.error(error)
        }
    }

    function togglePlay() {
        if (audio?.paused) {
            setShouldPlay(true)
            syncPlayback()
        } else {
            setShouldPlay(false)
            audio?.pause()
        }
    }

    function togglePlayMode() {
        if (playMode === 'local') setShouldPlay(true) // toggling to room, room should autoplay
        dispatch(Actions.setPlayMode(playMode === 'local' ? 'room' : 'local'))
    }

    function toggleQuality() {
        setHd( old => !old )
    }

    function trackUrl(track) {
        if (!track) return ''
        const hq = track.files?.find( file => file.key.endsWith('hq.m4a') )
        const lq = track.files?.find( file => file.key.endsWith('lq.m4a') )
        let url
        if (hd && hq) {
            url = `${cdnUrl}${hq.key}`
        } else {
            url = `${cdnUrl}${lq.key}`
        }
        return url
    }

    function updatePlaybackTime() {
        const duration = (playerData.durationSet && Number.isFinite(audio.duration)) ? audio.duration : 0
        const position = (playerData.durationSet && Number.isFinite(audio.duration)) ? Math.min(audio.currentTime, duration) : 0
        setPlayHeadTime(hoursMinsSecs(position))
        setPlayHeadPercent(playerData.durationSet ? position / duration : 0)
        navigator.mediaSession.setPositionState({ duration, position, playbackRate: audio.playbackRate })
        if (!playerData.scrubbing) {
            setScrubKnobPosition(document.getElementById('progressBar').clientWidth - 10)
        }
        if (playerData.durationSet && audio.currentTime >= audio.duration) {
            stopUpdates()
            reportPlaybackFinished()
        }
    }

    let skipButton
    if (userIsRoomAdmin && playMode === 'room')
        skipButton = <span className="material-symbols-outlined TrackControlButton" onClick={skip}>skip_next</span>

    return (
        <div className="Player">
            <div className="PlayerGrid">
                <div className="PlayerTrackInfo">
                    <div className="TrackTitle" onClick={() => dispatch(Actions.showSheet({ action: 'showTrack', data: track.id }))}>{ track?.title }</div>
                    <div className="TrackArtist">{ track?.artist }</div>
                </div>
                <div className="PlayerControls">
                    <span className="material-symbols-outlined TrackControlButton" onClick={togglePlay}>{ playing ? 'stop_circle' : 'play_circle' }</span>
                    <span className="material-symbols-outlined TrackControlButton" onClick={toggleQuality}>{ hd ? 'hd' : 'sd' }</span>
                    <span className="material-symbols-outlined TrackControlButton" onClick={togglePlayMode}>{ playMode === 'local' ? 'person' : 'group' }</span>
                    { skipButton }
                    <div className="PlayerTime">{ playHeadTime } / { hoursMinsSecs(audio.duration) }</div>
                    <div className="PlayerVolumeContainer">
                        <span className="material-symbols-outlined PlayerVolumeUp">volume_up</span>
                        <input type="range" className="PlayerVolumeSlider" value={ volume } onChange={ (e) => setVolume(e.target.value) } />
                    </div>
                </div>
                <div className="PlayerProgress">
                    <div className="PlayerProgressBar" id="progressBar" style={{ width: `${playHeadPercent * 100}%`}}/>
                    <div className="PlayerScrubKnob"
                         style={{ left: scrubKnobPosition, visibility: playMode === 'local' ? 'visible' : 'hidden' }}
                         onTouchStart={onScrubStart}
                         onTouchEnd={onScrubEnd}
                         onMouseDown={onScrubStart}
                         onMouseUp={onScrubEnd} />
                </div>
            </div>
        </div>
    )
}
