import { useAppContext, useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'
import { useMutation, useQuery } from '@apollo/client'
import RoomQueue from '../graphql/queries/RoomQueue'
import useCurrentRoom from '../hooks/useCurrentRoom'
import useUserIsCurrentRoomAdmin from '../hooks/useUserIsCurrentRoomAdmin'
import RemoveRequest from '../graphql/queries/RemoveRequest'

export default function Queue(props) {

    const appState = useAppContext()
    const { playMode, queue: localQueue } = appState
    const dispatch = useAppDispatch()
    const room = useCurrentRoom()
    const { data } = useQuery(RoomQueue, { fetchPolicy: 'cache-first', skip: !room, variables: { roomId: room.id }})
    const isAdmin = useUserIsCurrentRoomAdmin() || playMode === 'local'
    const [ removeTrackMutation ] = useMutation(RemoveRequest)
    const queue = playMode === 'local' ? localQueue : data?.room.queue ?? []
    const queueSection = queue.map( (entry, index) => [
        <div key={`${entry.track.id}-q${index}-p`} className={'QueueStatusIcon' + (index % 2 === 0 ? ' Even' : ' Odd')} style={{ gridRow: index + 1, gridColumn: 1 }}>
            { index === 0 ? <span className="material-symbols-outlined">brand_awareness</span> : '' }
        </div>,
        <div key={`${entry.track.id}-q${index}`} className={'QueueTrack' + (index % 2 === 0 ? ' Even' : ' Odd')} style={{ gridRow: index + 1, gridColumn: 2 }}>
            <span className="QueueTrackInfo">
                <span className="TrackTitle" onClick={() => showTrack(entry.track)}>{ entry.track.title }</span>
                <span className="TrackSeparator">by</span>
                <span className="TrackArtist">{ entry.track.artist }</span>
            </span>
        </div>,
        <div key={`${entry.track.id}-q${index}-c`} className={'QueueTrackControls' + (index % 2 === 0 ? ' Even' : ' Odd')} style={{ gridRow: index + 1, gridColumn: 3 }}>
            { entry.user ? <span className="QueueRequestor">{ entry.user.username }</span> : null }
            { isAdmin ? <span
                className="TrackControlButton material-symbols-outlined"
                style={{ marginRight: 0 }}
                onClick={() => removeTrack(index)}>delete</span>
                : null }
        </div>
    ])

    async function removeTrack(index) {
        if (playMode === 'room') {
            try {
                const entry = queue[index]
                await removeTrackMutation({
                    refetchQueries: [RoomQueue],
                    variables: {roomId: room.id, requestId: entry.id}
                })
            } catch (error) {
                // ignore
            }
        } else {
            dispatch(Actions.removeFromQueue(index))
        }
    }

    function showTrack(track) {
        dispatch(Actions.showSheet({ action: 'showTrack', data: track.id }))
    }

    return (
        <div className="Queue">
            { queueSection }
        </div>
    )
}