import { useMutation } from '@apollo/client'
import VerifyAccount from '../graphql/queries/VerifyAccount'
import { useState } from 'react'
import Spinner from '../ui/Spinner'

export default function VerifyingModal(props) {

    const [verifyMutation] = useMutation(VerifyAccount, { variables: { code: props.code } })
    const [verificationStarted, setVerificationStarted] = useState(false)

    function verifyFailed() {
        props.show('verifyFailed')
    }

    function verifySucceeded() {
        props.show('verified')
    }

    async function startVerification() {
        const result = await verifyMutation({ variables: { code: props.code }})
        if (result) {
            verifySucceeded()
        } else {
            verifyFailed()
        }
    }

    if (!verificationStarted) {
        setVerificationStarted(true)
        startVerification()
    }

    return (
        <div className="Dimmer">
            <div className="FullPadding">
            <div className="ContentBox HalfPadding PopUp" onClick={(e)=>{e.stopPropagation()}}>
                <div style={{ textAlign: 'center' }}>
                    <p className="Message">Verifying your account...</p>
                    <Spinner />
                </div>
            </div>
            </div>
        </div>
    )
}
