import { useEffect, useState } from 'react'
import Spinner from './Spinner'
import { useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'

export default function ProgressModal(props) {

    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [abortController] = useState(new AbortController())
    const { text, action } = props

    useEffect(() => {
        performAction()
    }, [])

    function cancel() {
        abortController.abort()
        setLoading(false)
        dispatch(Actions.dismissMainModal())
    }

    async function performAction() {
        setLoading(true)
        try {
            const result = await action?.()
            setLoading(false)
            dispatch(Actions.dismissMainModal())
            if (result)
                dispatch(Actions.showToast({ content: result }))
        } catch (error) {
            setErrorMessage(error.message)
            setLoading(false)
        }
    }

    return (
        <div className="FullPadding">
            <div className="ContentBox HalfPadding PopUp">
                <div>{ text }</div>
                <div className="ModalButtons">
                    { loading ? <div><Spinner /></div> : null }
                    <button style={{ marginRight: '10px' }} onClick={cancel}>Cancel</button>
                </div>
                { errorMessage ? <div><p className="ErrorMessage">{ errorMessage }</p></div> : null }
            </div>
        </div>
    )
}