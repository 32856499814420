import { useEffect, useRef, useState } from 'react'
import { useAppContext, useAppDispatch } from '../../AppContext'
import { Actions as Action, Actions } from '../../AppReducer'
import ChatContainer from './ChatContainer'
import { useApolloClient, useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client'
import AddChat from '../../graphql/queries/AddChat'
import History from '../../graphql/queries/History'
import RoomEvents from '../../graphql/queries/RoomEvents'
import useCurrentRoom from '../../hooks/useCurrentRoom'
import Spinner from '../../ui/Spinner'
import useCurrentUser from '../../hooks/useCurrentUser'

export default function Chat() {

    const dispatch = useAppDispatch()
    const apolloClient = useApolloClient()

    const [commandHistory, setCommandHistory] = useState([])
    const [line, setLine] = useState('')
    const [lineBackup, setLineBackup] = useState(null)
    const [commandHistoryCursor, setCommandHistoryCursor] = useState(0)
    const inputRef = useRef(null)
    const [scrollingPaused, setScrollingPaused] = useState(false)
    const terminalContainerRef = useRef(null)
    const { activeTab } = useAppContext()
    const [lastScrollHeight, setLastScrollHeight] = useState(0)

    // Refocus on the input box when returning to chat
    useEffect(() => {
        if (activeTab === 'listen') {
            inputRef.current?.focus()
        }
    }, [activeTab, inputRef.current])

    const [online, setOnline] = useState(true)
    const [visible, setVisible] = useState(true)
    useEffect(() => {
        window.addEventListener('online', () => setOnline(true))
        window.addEventListener('offline', () => setOnline(false))
        document.addEventListener('visibilitychange', () => setVisible(document.visibilityState === 'visible'))
    }, [])

    const room = useCurrentRoom()
    const roomId = room?.id
    const user = useCurrentUser()
    const [ addChatMutation ] = useMutation(AddChat)
    const {
        loading: loadingSubscription,
        restart: restartSubscription
    } = useSubscription(RoomEvents, {
        onComplete,
        onData,
        onError,
        shouldResubscribe: true,
        skip: !roomId,
        variables: { id: roomId }
    })

    const [ fetchHistory, { data: historyData, fetchMore: fetchMoreHistory, loading: loadingHistory} ] = useLazyQuery(
        History, {
            notifyOnNetworkStatusChange: true,
            skip: !roomId,
            variables: { roomId }
        }
    )

    useEffect(() => {
        console.log(`loadingSubscription: ${loadingSubscription}`)
        if (!loadingSubscription) {
            apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'history' })
            fetchHistory()
        }
    }, [loadingSubscription])

    useEffect(() => {
        roomId && dispatch(Actions.setPlayMode('room'))
    }, [roomId])

    useEffect(() => {
        const term = terminalContainerRef.current
        if (term && Math.abs(term.scrollHeight - term.clientHeight - term.scrollTop) >= 1 && !scrollingPaused) {
            setTimeout(() => {
                const targetTop = term.scrollHeight - term.clientHeight
                term.scrollTo({ left: 0, top: targetTop, behavior: 'smooth' })
                console.log(`got new history: scrolled to ${targetTop}`)
            }, 500)
        }
        if (lastScrollHeight) {
            setLastScrollHeight(0)
            const diff = term.scrollHeight - lastScrollHeight
            term.scrollTop += diff
            console.log(`got new scrollback history: set scrollTop to ${term.scrollTop}`)
        }
    }, [historyData, loadingHistory])

    function addCommandHistory() {
        const newHistory = [ line, ...commandHistory ]
        if (newHistory.length > 100)
            newHistory.pop()
        setCommandHistory(newHistory)
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter') {
            addCommandHistory()
            processInput(line)
            setLine('')
        } else if (e.key === 'ArrowUp') {
            if (commandHistory.length === 0) {
                // Do nothing
            } else if (commandHistoryCursor === 0 && lineBackup == null) {
                setLineBackup(line)
                setLine(commandHistory[0])
                const end = commandHistory[0].length
                setTimeout(() => inputRef.current.setSelectionRange(end, end), 0)
            } else {
                const newCursor = Math.min(commandHistory.length - 1, commandHistoryCursor + 1)
                setCommandHistoryCursor(newCursor)
                setLine(commandHistory[newCursor])
                const end = commandHistory[newCursor].length
                setTimeout(() => inputRef.current.setSelectionRange(end, end), 0)
            }
        } else if (e.key === 'ArrowDown') {
            if (commandHistoryCursor === 0 && lineBackup != null) {
                setLine(lineBackup)
                setLineBackup(null)
            } else if (commandHistoryCursor > 0) {
                const newCursor = commandHistoryCursor - 1
                setCommandHistoryCursor(newCursor)
                setLine(commandHistory[newCursor])
                const end = commandHistory[newCursor].length
                inputRef.current.setSelectionRange(end, end)
            }
        }
    }

    function handleScroll(e) {
        const {scrollTop, scrollHeight, clientHeight} = e.target
        // console.log(`handleScroll: scrollTop => ${scrollTop}`)
        const position = Math.ceil((scrollTop / (scrollHeight - clientHeight)) * 100)
        if (position < 100) setScrollingPaused(true)
        else if (scrollingPaused) setScrollingPaused(false)

        if (!loadingHistory && position === 0 && historyData?.history.cursor) {
            console.log(`fetching more history: cursor: ${historyData?.history.cursor}`)
            fetchMoreHistory({ variables: { cursor: historyData.history.cursor }})
            setLastScrollHeight(terminalContainerRef.current.scrollHeight)
        }
    }

    function onComplete(data) {
        console.log(`SUBSCRIPTION COMPLETED: ${JSON.stringify(data)}`)
        setTimeout(restartSubscription, 5000)
    }

    function onData(observable) {
        // console.log(`data => ${JSON.stringify(observable.data, null, 4)}`)
        const event = observable.data.data.roomEvents
        if (!event) {
            console.warn(`unexpected subscription data: ${JSON.stringify(observable.data, null, 4)}`)
            return
        }
        if (historyData?.history.events.find(history => history.id === event.id)) {
            console.warn(`duplicate event id found`)
            return
        } // it's a dupe
        apolloClient.cache.writeQuery({
            query: History,
            data: {
                history: {
                    events: [
                        event
                    ],
                    cursor: historyData?.history.cursor
                }
            },
            variables: {
                roomId
            }
        })
    }

    function onError(data) {
        console.log(`SUBSCRIPTION ERROR: ${JSON.stringify(data)}`)
        setTimeout(restartSubscription, 5000)
    }

    function renderEvent(event) {
        switch (event.__typename) {
            case 'AdminAdded': {
                const { actor: { username: actorUsername }, admin: { username: adminUsername } } = event
                return <span className="ChatInfo">{actorUsername} made {adminUsername} a room admin.</span>
            }
            case 'AdminRemoved': {
                const { actor: { username: actorUsername }, admin: { username: adminUsername } } = event
                return <span className="ChatInfo">{actorUsername} removed {adminUsername}'s room admin privileges.  Music they have contributed will no longer be available to the room.</span>
            }
            case 'ChatAdded': {
                const { actor: { username }, text } = event
                let message = ''
                if (text[0] === ':') {
                    message = `${username} ${text.substring(1)}`
                } else {
                    const verb = text.slice(-1) === '?' ? 'asks' : 'says'
                    message = `${username} ${verb}, "${text}"`
                }
                return <span>{ message }</span>
            }
            case 'MemberAdded': {
                const { actor: { username: actorName }, member: { username: memberName } } = event
                return <span className="ChatInfo">{ memberName } accepted an invite to the room from { actorName }.</span>
            }
            case 'MemberRemoved': {
                const { actor: { username: actorName }, member: { username: memberName } } = event
                return <span className="ChatInfo">{ actorName } removed { memberName } from the room.</span>
            }
            case 'RequestAdded': {
                const { request: { track }, actor: { username } } = event
                if (!track) return <span className="ChatInfo">{ username } requested a track that no longer exists.</span>
                return <span className="ChatInfo">{ username } requested { trackLink(track) } by <b>{ track.artist }</b>.</span>
            }
            case 'RequestRemoved': {
                const { request: { track }, actor: { username } } = event
                if (!track) return <span className="ChatInfo">{ username } removed a track that no longer exists from the request queue.</span>
                return <span className="ChatInfo">{ username } removed { trackLink(track) } from the request queue.</span>
            }
            case 'RequestSkipped': {
                const { request: { track }, actor: { username } } = event
                if (!track) return <span className="ChatInfo">{ username } skipped a track that no longer exists.</span>
                return <span className="ChatInfo">{ username } skipped { trackLink(track) }.</span>
            }
            case 'RequestStarted': {
                const { request: { track } } = event
                if (!track) return <span className="ChatInfo">Now playing a track that no longer exists.</span>
                return <span className="ChatInfo">Now playing { trackLink(track) } by <b>{ track.artist }</b>.</span>
            }
            case 'RoomUpdated': {
                const { actor: { username }, updates: { spinning, name, topic } } = event
                if (spinning) {
                    return <span className="ChatInfo">{username} changed the spinning tag to <b>{spinning}</b>.</span>
                } else if (name) {
                    return <span className="ChatInfo">{username} changed the name of the room to <b>{name}</b>.</span>
                } else if (topic) {
                    return <span className="ChatInfo">{username} changed the topic to <b>{topic}</b>.</span>
                } else {
                    // TODO: Invite?
                    return null
                }
            }
            case 'Subscribed':
                return // reconnects are silent
            default:
                console.log(`unhandled room event data: ${JSON.stringify(event)}`)
        }
    }

    async function processInput(text) {
        try {
            await addChatMutation({ variables: { roomId, text } })
        } catch (error) {
            console.error('failed to add chat', error)
        }
    }

    function trackLink(track) {
        return <span className="TrackLink" onClick={() => dispatch(Action.showSheet({ action: 'showTrack', data: track.id }))}>{ track.title }</span>
    }

    function updateInput(e) {
        const newText = e.target.value.replace('\n', '')
        setLine(newText)
    }

    const chatLines = historyData?.history.events.toReversed().map( event => <div className="ChatLine" key={event.id}>{ renderEvent(event) }</div> )

    let emptyContent
    if (!user) {
        return <div style={{ width: '100%', textAlign: 'center' }}><Spinner/></div>
    } else if (!user.username) {
        return (
            <div className="ChatPage">
                <div className="ChatEmpty">
                    <p>You haven't set your username.  You need a username to chat.</p>
                    <p>You can set your username on the <span onClick={() => dispatch(Actions.navigateTo('profile'))} className="Link">profile screen</span>.</p>
                </div>
            </div>
        )
    } else if (!room) {
        emptyContent = (
            <div className="ChatEmpty">
                <p>You are not a member of any Tunistry listening rooms.</p>
                <p>You can join one by accepting an invite from another user on the <span onClick={() => dispatch(Actions.navigateTo('profile'))} className="Link">profile</span> screen, or <span onClick={() => dispatch(Actions.showSheet({ action: 'showCreateRoom' }))} className="Link">create one of your own</span>.</p>
            </div>
        )
    } else if (room.members.length < 2) {
        emptyContent = (
            <div className="ChatEmpty">
                <p>You are the only member of this listening room.</p>
                <p><span onClick={() => dispatch(Actions.showSheet({ action: 'showRoomDetails', data: room.id }))} className="Link">Invite your friends</span> to join you and listen together.</p>
            </div>
        )
    }

    return (
        <div className="ChatPage">
            { room ? [
                <div key={2} className="TerminalContainer" ref={terminalContainerRef} onScroll={handleScroll}>
                    <ChatContainer>
                        { loadingHistory ? <div style={{ width: '100%', textAlign: 'center' }}><Spinner/></div> : null }
                        { chatLines }
                    </ChatContainer>
                </div>,
                <div key={3} className="TerminalInputContainer">
                    <textarea ref={inputRef} rows={3} className="TerminalInput" value={line} onChange={updateInput} onKeyDown={handleKeyPress} />
                </div>
            ] : null }
            { emptyContent }
        </div>
    )
}
