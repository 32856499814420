import { useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'
import { useMutation } from '@apollo/client'
import AddMusic from '../graphql/queries/AddMusic'

export default function ImportControls(props) {

    const dispatch = useAppDispatch()
    const [addMusicMutation] = useMutation(AddMusic)

    const { track, addToRoom } = props

    async function addTrack() {
        const result = await addMusicMutation({ variables: { roomId: addToRoom, trackIds: [track.id] } })
        if (result.data) {
            dispatch(Actions.showToast({content: <span>Added <i>{track.title}</i> to the room.</span>}))
        } else {
            dispatch(Actions.showToast({content: <span>Could not add <i>{track.title}</i> to the room.</span>}))
        }
    }

    return (
        <div className="TrackControlButtons">
            <span className="material-symbols-outlined TrackControlButton" onClick={(e) => dispatch(Actions.playTrack(track))}>play_circle</span>
            <span className="material-symbols-outlined TrackControlButton" onClick={addTrack}>add_circle</span>
        </div>
    )
}