import RefreshAccessToken from '../graphql/queries/RefreshAccessToken'

export async function refreshAccessToken(refreshToken) {
    const result = await global.apolloClient.mutate({
        mutation: RefreshAccessToken,
        variables: { refreshToken },
        fetchPolicy: 'no-cache',
        // update: updateUser
    })
    const data = result.data?.refreshAccessToken
    localStorage.setItem('at', data?.accessToken)
    localStorage.setItem('rt', data?.refreshToken)
    return data?.accessToken
}
