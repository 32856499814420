import { onError } from '@apollo/client/link/error'
import logout from '../../auth/logout'

export const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) {
        if (networkError.statusCode === 401) {
            console.error('Received 401 response from server')
            if (localStorage.getItem('at'))
                logout()
        } else {
            console.error(`[Network error]: ${networkError.message} ${networkError.stack}`)
        }
    }
})
