import {useEffect, useState} from 'react'
import { hoursMinsSecs } from '../util/time'
import EditText from '../ui/EditText'
import TagView from './TagView'
import { useAppDispatch } from '../AppContext'
import { sizeFor } from '../util/text'
import { Actions } from '../AppReducer'
import { useMutation, useQuery } from '@apollo/client'
import useCurrentUser from '../hooks/useCurrentUser'
import DeleteTrack from '../graphql/queries/DeleteTrack'
import RemoveTracks from '../graphql/queries/RemoveTrack'
import UpdateTrack from '../graphql/queries/UpdateTrack'
import Track from '../graphql/queries/Track'
import Tracks from '../graphql/queries/Tracks'
import Spinner from '../ui/Spinner'

export default function TrackView(props) {

    const dispatch = useAppDispatch()
    const [deleteTrackMutation] = useMutation(DeleteTrack, {
        refetchQueries: [ Tracks ]
    })
    const [removeTracksMutation] = useMutation(RemoveTracks, {
        refetchQueries: [ Tracks ]
    })
    const [updateTrackMutation] = useMutation(UpdateTrack)
    const { loading, data } = useQuery(Track, {
        fetchPolicy: 'cache-and-network',
        variables: { id: props.trackId }
    })

    const [editing, setEditing] = useState(null)
    const [album, setAlbum] = useState('')
    const [artist, setArtist] = useState('')
    const [composer, setComposer] = useState('')
    const [date, setDate] = useState('')
    const [genre, setGenre] = useState('')
    const [title, setTitle] = useState('')
    const [track, setTrack] = useState('')
    const [tags, setTags] = useState([])

    const trackData = data?.track

    const user = useCurrentUser()

    const duration = hoursMinsSecs(trackData?.duration ?? 0)
    const size = sizeFor(trackData?.size ?? 0)
    const uploadDate = new Date(trackData?.completed).toLocaleString()

    useEffect(() => {
        resetStateForNewTrack()
    }, [trackData])

    function addTag(tag) {
        if (tags.includes(tag)) return
        const newTags = [...tags, tag].sort()
        //setTags(newTags)
        saveField('tags', newTags)
    }

    function changeFocus(field) {
        if (userCanEdit())
            setEditing(field)
    }

    function deleteTrack() {
        dispatch(Actions.showModal({
            action: 'confirmation',
            text: 'Deleting the track will permanently remove it from your uploads and any listening rooms you have contributed it to.  Are you sure you want to proceed?',
            confirmText: 'Delete',
            confirmAction: async () => {
                if (! await deleteTrackMutation({ variables: { trackId: props.trackId } })) {
                    throw Error('Could not delete the track.  Try again later.')
                }
                dispatch(Actions.dismissMainSheet())
            }
        }))
    }

    function removeFromRoom() {
        dispatch(Actions.showModal({
            action: 'confirmation',
            text: `Are you sure you want to remove ${title} by ${artist}?`,
            confirmText: 'Remove',
            confirmAction: async () => {
                if (!await removeTracksMutation({
                    variables: { roomId: trackData?.roomId, trackIds: [props.trackId] }
                })) {
                    throw Error('Could not remove the track from the room.  Try again later.')
                }
                dispatch(Actions.dismissMainSheet())
            }
        }))
    }

    function removeTag(tag) {
        const newTags = tags.filter( candidate => candidate !== tag )
        setTags(newTags)
        saveField('tags', newTags)
    }

    function resetStateForNewTrack() {
        if (props.trackId !== trackData?.id)
            setEditing(null)
        setAlbum(trackData?.album)
        setArtist(trackData?.artist)
        setComposer(trackData?.composer)
        setDate(trackData?.date)
        setGenre(trackData?.genre)
        setTitle(trackData?.title)
        setTrack(trackData?.track)
        setTags(trackData?.tags ?? [])
    }

    async function saveField(field, value) {
        if (trackData[field] !== value) {
            try {
                await updateTrackMutation({ variables: { trackId: props.trackId, updates: { [field]: value } } })
            } catch (error) {
                dispatch(Actions.showToast({ content: <span className="ErrorMessage">Error updating track: { error.message }</span> }))
            }
        }
    }

    function userCanEdit() {
        if (!trackData?.roomId && trackData?.userId === user?.id) return true // user's own track
        const room = user?.rooms.find( r => r.id === trackData?.roomId )
        if (room?.admins.find( admin => admin.id === user?.id )) return true // room admin
        return false
    }

    let deleteButton
    if (trackData?.roomId) {
        const trackRoom = user.rooms.find( room => room.id === trackData.roomId )
        const userCanRemoveFromRoom = trackRoom?.admins.find( admin => admin.id === user.id )
        if (userCanRemoveFromRoom)
            deleteButton = <button onClick={removeFromRoom}> Remove from Room </button>
    } else {
        deleteButton = <button onClick={deleteTrack}> Delete Track </button>
    }

    if (loading) return <div style={{ padding: '20px' }}><Spinner /></div>

    return (
        <div className="TrackGrid">
            <div className="GridLabel" style={{ gridArea: 'titleLabel'}} onClick={() => changeFocus('')}>Title</div>
            <div className="GridText" style={{ gridArea: 'title', borderTopRightRadius: '21px' }}>
                <EditText
                    text={title}
                    onClick={() => changeFocus('title')}
                    onBlur={() => saveField('title', title)}
                    updateText={(e) => setTitle(e.target.value)}
                    editEnabled={editing === 'title'}
                />
            </div>
            <div className="GridLabel" style={{ gridArea: 'artistLabel'}} onClick={() => changeFocus('')}>Artist</div>
            <div className="GridText" style={{ gridArea: 'artist'}}>
                <EditText
                    onClick={() => changeFocus('artist')}
                    onBlur={() => saveField('artist', artist)}
                    text={artist}
                    updateText={(e) => setArtist(e.target.value)}
                    editEnabled={editing === 'artist'}
                />
            </div>
            <div className="GridLabel" style={{ gridArea: 'albumLabel'}} onClick={() => changeFocus('')}>Album</div>
            <div className="GridText" style={{ gridArea: 'album'}}>
                <EditText
                    onClick={() => changeFocus('album')}
                    onBlur={() => saveField('album', album)}
                    text={album}
                    updateText={(e) => setAlbum(e.target.value)}
                    editEnabled={editing === 'album'}
                />
            </div>
            <div className="GridLabel" style={{ gridArea: 'trackLabel'}} onClick={() => changeFocus('')}>Track</div>
            <div className="GridText" style={{ gridArea: 'track'}}>
                <EditText
                    onClick={() => changeFocus('track')}
                    onBlur={() => saveField('track', track)}
                    text={track}
                    updateText={(e) => setTrack(e.target.value)}
                    editEnabled={editing === 'track'}
                />
            </div>
            <div className="GridLabel" style={{ gridArea: 'dateLabel'}} onClick={() => changeFocus('')}>Date</div>
            <div className="GridText" style={{ gridArea: 'date'}}>
                <EditText
                    onClick={() => changeFocus('date')}
                    onBlur={() => saveField('date', date)}
                    text={date}
                    updateText={(e) => setDate(e.target.value)}
                    editEnabled={editing === 'date'}
                />
            </div>
            <div className="GridLabel" style={{ gridArea: 'composerLabel'}} onClick={() => changeFocus('')}>Composer</div>
            <div className="GridText" style={{ gridArea: 'composer'}}>
                <EditText
                    onClick={() => changeFocus('composer')}
                    onBlur={() => saveField('composer', composer)}
                    text={composer}
                    updateText={(e) => setComposer(e.target.value)}
                    editEnabled={editing === 'composer'}
                />
            </div>
            <div className="GridLabel" style={{ gridArea: 'genreLabel'}} onClick={() => changeFocus('')}>Genre</div>
            <div className="GridText" style={{ gridArea: 'genre'}}>
                <EditText
                    onClick={() => changeFocus('genre')}
                    onBlur={() => saveField('genre', genre)}
                    text={genre}
                    updateText={(e) => setGenre(e.target.value)}
                    editEnabled={editing === 'genre'}
                />
            </div>
            <div className="GridLabel" style={{ gridArea: 'tagsLabel' }} onClick={() => changeFocus('')}>Tags</div>
            <div className="GridText" style={{ gridArea: 'tags'}}>
                <TagView
                    onClick={() => changeFocus('tags')}
                    onAddTag={addTag}
                    onRemoveTag={removeTag}
                    editing={editing === 'tags'}
                    tags={tags}
                />
            </div>
            { deleteButton ? [
                <div key={1} className="GridLabel" style={{ gridArea: 'buttonsLabel' }} onClick={() => changeFocus('')}/>,
                <div key={2} className="GridText" style={{ gridArea: 'buttons' }} onClick={() => changeFocus('')}>{deleteButton}</div>
            ] : null }
            <div className="GridSpanLabel" style={{ gridArea: 'mediaInfoLabel' }} onClick={() => changeFocus('')}>Media Info</div>
            <div className="GridLabel" style={{ gridArea: 'sizeLabel' }} onClick={() => changeFocus('')}>Size</div>
            <div className="GridText" style={{ gridArea: 'size' }} onClick={() => changeFocus('')}>{ size }</div>
            <div className="GridLabel" style={{ gridArea: 'durationLabel' }} onClick={() => changeFocus('')}>Duration</div>
            <div className="GridText" style={{ gridArea: 'duration' }} onClick={() => changeFocus('')}>{ duration }</div>
            <div className="GridLabel" style={{ gridArea: 'codecLabel' }} onClick={() => changeFocus('')}>Codec</div>
            <div className="GridText" style={{ gridArea: 'codec' }} onClick={() => changeFocus('')}>{ trackData?.codec }</div>
            <div className="GridLabel" style={{ gridArea: 'bitRateLabel' }} onClick={() => changeFocus('')}>Bit Rate</div>
            <div className="GridText" style={{ gridArea: 'bitRate' }} onClick={() => changeFocus('')}>{ trackData?.bitRate }</div>
            <div className="GridLabel" style={{ gridArea: 'uploadDateLabel' }} onClick={() => changeFocus('')}>Uploaded</div>
            <div className="GridText" style={{ gridArea: 'uploadDate' }} onClick={() => changeFocus('')}>{ uploadDate }</div>
            { trackData?.roomId ?
                [
                    <div key={3} className="GridLabel" style={{ gridArea: 'contributorsLabel' }} onClick={() => changeFocus('')}>Contributors</div>,
                    <div key={4} className="GridText" style={{ gridArea: 'contributors' }} onClick={() => changeFocus('')}>{ trackData?.contributors.map( user => <span>{ user.username }</span> ) }</div>
                ]
            : null }
        </div>
    )
}
