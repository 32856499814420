import { useState } from 'react'
import { useAppContext, useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'
import Listen from '../listen/Listen'
import MainModal from './MainModal'
import MainSheet from './MainSheet'
import Player from '../player/Player'
import Profile from '../profile/Profile'
import Search from '../library/search/Search'
import Upload from '../upload/Upload'
import Toast from './Toast'
import '../App.css'
import useCurrentUser from '../hooks/useCurrentUser'
import ProfileAvatar from '../profile/ProfileAvatar'
import RoomMemberSummary from '../listen/RoomMemberSummary'

function Main() {

    const { activeTab, mainSheetOpen } = useAppContext()
    const dispatch = useAppDispatch()
    global.uiDispatch = dispatch // A cheat to allow us to dispatch directly from Api functions

    const user = useCurrentUser()
    const [avatarUrl, setAvatarUrl] = useState('')
    const { navigateTo } = Actions

    const tabs = [
        <div className={ activeTab === 'listen' ? 'ActiveTab' : 'InactiveTab' } key={2}> <Listen/> </div>,
        <div className={ activeTab === 'search' ? 'ActiveTab' : 'InactiveTab' } key={3}> <Search/> </div>,
        <div className={ activeTab === 'profile' ? 'ActiveTab' : 'InactiveTab' } key={4}> <Profile/> </div>,
        <div className={ activeTab === 'upload' ? 'ActiveTab' : 'InactiveTab' } key={1}> <Upload/> </div>
    ]

    return (
        <div className="MainContainer">
            <div className="Main">
                <div className="MainContent">
                    <div className="MainHeader">
                        <div className="MainTabs">
                            <div className={activeTab === 'listen' ? 'MainTab Active' : 'MainTab'} onClick={ () => dispatch(navigateTo('listen')) }>Listen</div>
                            <div className={activeTab === 'search' ? 'MainTab Active' : 'MainTab'} onClick={ () => dispatch(navigateTo('search')) }>Library</div>
                            <div className={activeTab === 'upload' ? 'MainTab Active' : 'MainTab'} onClick={ () => dispatch(navigateTo('upload')) }>Upload</div>
                        </div>
                        <div className="MainButtons">
                            <RoomMemberSummary />
                            <div onClick={() => dispatch(navigateTo('profile'))}>
                                <ProfileAvatar user={user} />
                            </div>
                        </div>
                    </div>
                    <div className="MainTabContent">
                        <div className="MainTabContentScroller">
                        { tabs }
                        </div>
                    </div>
                    <div className="MainContentCover" style={{ visibility: mainSheetOpen ? 'visible' : 'hidden' }}/>
                    <MainSheet />
                    <MainModal />
                    <Toast />
                </div>
                <Player className="Player" />
            </div>
        </div>
    )
}

export default Main
