import { useReducer } from 'react'
import { validTokenExists } from './auth/token'
export const useAppReducer = () => useReducer(appReducer, initialAppState)

function appReducer(appState, action) {
    // console.log(`action => ${JSON.stringify(action, null, 4)}`)
    switch (action.name) {
        case 'dismissMainModal':
            return { ...appState, mainModalOpen: false, mainModalState: null }
        case 'dismissMainSheet':
            return { ...appState, mainSheetOpen: false, mainSheetState: null }
        case 'dismissToast':
            return { ...appState, showToast: false }
        case 'logIn':
            return { ...appState, loggedIn: true }
        case 'logOut':
            return { ...appState, loggedIn: false }
        case 'navigateTo':
            return { ...appState, activeTab: action.value }
        case 'playTrack':
            return { ...appState, queue: [ { track: action.value, playTime: Date.now() } ], playMode: 'local' }
        case 'queueTrack':
            const newEntry = { track: action.value }
            if (appState.queue?.length === 0) newEntry.playTime = Date.now()
            return { ...appState, queue: [ ...appState.queue, newEntry ] }
        case 'removeFromQueue':
            return { ...appState, queue: appState.queue.filter((item, index) => index !== action.value) }
        case 'removeTracks':
            return { ...appState, removeTracks: action.value }
        case 'setCurrentRoom':
            localStorage.setItem('currentRoom', action.value)
            return { ...appState, currentRoom: action.value }
        case 'setPlayMode':
            return { ...appState, playMode: action.value }
        case 'setQueue':
            return { ...appState, queue: action.value }
        case 'showModal':
            return { ...appState, mainModalState: action.value, mainModalOpen: true }
        case 'showSheet':
            return { ...appState, mainSheetState: action.value, mainSheetOpen: true }
        case 'showToast':
            return { ...appState, toastState: action.value, showToast: true }
        default:
            throw new Error(`Unexpected state in action: ${JSON.stringify(action)}`)
    }
}

export const Actions = {
    dismissMainModal: () => ({
        name: 'dismissMainModal'
    }),
    dismissMainSheet: () => ({
       name: 'dismissMainSheet'
    }),
    dismissToast: () => ({
        name: 'dismissToast'
    }),
    logIn: () => ({
        name: 'logIn'
    }),
    logOut: () => ({
        name: 'logOut'
    }),
    navigateTo: (dest) => ({
        name: 'navigateTo',
        value: dest
    }),
    playTrack: (track) => ({
        name: 'playTrack',
        value: track
    }),
    queueTrack: (track) => ({
       name: 'queueTrack',
       value: track
    }),
    removeFromQueue: (index) => ({
        name: 'removeFromQueue',
        value: index
    }),
    removeTracks: (tracks) => ({
        name: 'removeTracks',
        value: tracks
    }),
    setCurrentRoom: (roomId) => ({
        name: 'setCurrentRoom',
        value: roomId
    }),
    setPlayMode: (mode) => ({
        name: 'setPlayMode',
        value: mode
    }),
    setQueue: (queue) => ({
        name: 'setQueue',
        value: queue
    }),
    showSheet: (data) => ({
       name: 'showSheet',
       value: data
    }),
    showModal: (data) => ({
       name: 'showModal',
       value: data
    }),
    showToast: (data) => {
        if (data.timeout !== -1)
            setTimeout(() => global.uiDispatch(Actions.dismissToast()), data.timeout ?? 3000)
        return {
            name: 'showToast',
                value: data
        }
    }
}

const initialAppState = {
    activeTab: 'listen',
    currentRoom: null,
    loggedIn: validTokenExists(),
    mainModalOpen: false,
    mainModalState: null,
    mainSheetOpen: false,
    mainSheetState: null,
    playMode: 'local',
    queue: [],
    removeTracks: [],
    showToast: false,
    toastState: null,
}
