import { useAppContext, useAppDispatch } from '../AppContext'
import { Actions } from '../AppReducer'
import Sheet from './Sheet'
import TrackView from '../track/TrackView'
import AlbumView from '../track/AlbumView'
import CreateRoom from '../listen/chat/CreateRoom'
import RoomDetails from '../listen/chat/RoomDetails'
import ImportMusic from '../listen/ImportMusic'
import QueueMusic from '../listen/QueueMusic'

export default function MainSheet() {
    const appState = useAppContext()
    const dispatch = useAppDispatch()

    const { mainSheetOpen, mainSheetState } = appState

    let sheetContent
    let sheetTitle
    switch (mainSheetState?.action) {
        case 'showAlbum':
            sheetContent = <AlbumView track={mainSheetState.data} />
            sheetTitle = 'Album Info'
            break
        case 'showAddMusic':
            sheetContent = <ImportMusic roomId={mainSheetState.data} />
            sheetTitle = 'Import Music From Your Library'
            break
        case 'showCreateRoom':
            sheetContent = <CreateRoom />
            sheetTitle = 'Create Room'
            break
        case 'showQueueMusic':
            sheetContent = <QueueMusic roomId={mainSheetState.data} />
            sheetTitle = 'Request Music'
            break
        case 'showRoomDetails':
            sheetContent = <RoomDetails room={mainSheetState.data} />
            sheetTitle = 'Room Details'
            break
        case 'showTrack':
            console.log(`mainSheet: showTrack: ${JSON.stringify(mainSheetState.data)}`)
            sheetContent = <TrackView trackId={mainSheetState.data} />
            sheetTitle = 'Track Info'
            break
        default:
            // no content
    }

    return (
        <Sheet title={sheetTitle} open={mainSheetOpen} onClose={() => dispatch(Actions.dismissMainSheet())}>
            { sheetContent }
        </Sheet>
    )
}