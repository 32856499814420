import { useAppDispatch } from '../../AppContext'
import { Actions } from '../../AppReducer'

export default function SearchTrackControls(props) {
    const dispatch = useAppDispatch()
    const { track } = props
    return (
        <div className="TrackControlButtons">
            <span className="material-symbols-outlined TrackControlButton" onClick={(e) => dispatch(Actions.playTrack(track))}>play_circle</span>
        </div>
    )
}
