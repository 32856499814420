import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import BasePlugin from '@uppy/core/lib/BasePlugin.js'
import { createSHA1 } from 'hash-wasm'
import _ from 'highland/dist/highland'

import AbortMultipartUpload from '../graphql/queries/AbortMultipartUpload'
import CompleteMultipartUpload from '../graphql/queries/CompleteMultipartUpload'
import CreateTrack from '../graphql/queries/CreateTrack'
import MultipartUploadParts from '../graphql/queries/MultipartUploadParts'
import MultipartUploadSignature from '../graphql/queries/MultipartUploadSignature'
import StartMultipartUpload from '../graphql/queries/StartMultipartUpload'
import UploadSignature from '../graphql/queries/UploadSignature'

async function abortUpload(uploadId, hash, signal) {
    const result = await global.apolloClient.mutate({
        mutation: AbortMultipartUpload,
        variables: { uploadId, hash }
    })
    return result.data.abortMultipartUpload
}

async function completeUpload(uploadId, hash, parts, signal) {
    const result = await global.apolloClient.mutate({
        mutation: CompleteMultipartUpload,
        variables: { uploadId, hash, parts }
    })
    return result.data.completeMultipartUpload
}

async function createUpload(hash, signal) {
    const result = await global.apolloClient.mutate({
        mutation: CreateTrack,
        variables: { hash }
    })
    return result.data.createTrack

}

async function getUploadParts(uploadId, hash, signal) {
    const result = await global.apolloClient.query({
        query: MultipartUploadParts,
        fetchPolicy: 'no-cache',
        variables: { hash }
    })
    return result.data.multipartUploadParts
}

async function signUploadPart(uploadId, hash, partNumber, signal) {
    const result = await global.apolloClient.query({
        query: MultipartUploadSignature,
        fetchPolicy: 'no-cache',
        variables: { uploadId, hash, partNumber }
    })
    return { url: result.data.multipartUploadSignature }
}

async function startUpload(hash, signal) {
    const result = await global.apolloClient.query({
        query: UploadSignature,
        fetchPolicy: 'no-cache',
        variables: { hash }
    })
    console.log(`result => ${JSON.stringify(result)}`)
    const data = {
        url: result.data.uploadSignature,
        method: 'PUT',
        headers: { 'content-type': 'application/octet-stream' }
    }
    console.log(`returning ${JSON.stringify(data, null, 4)}`)
    return data
}

async function startMultipartUpload(hash, signal) {
    const result = await global.apolloClient.mutate({
        mutation: StartMultipartUpload,
        variables: { hash }
    })
    return { uploadId: result.data.startMultipartUpload, key: hash }
}

class HashPlugin extends BasePlugin {
    constructor(uppy, opts = {}) {
        super(uppy, opts)
        this.id = opts.id || 'HashPlugin'
        this.type = 'preprocessor'
    }

    // TODO: pass abort signal into these pipeline funcs
    addHashToUppyFile = (uppyFile) => {
        const promise = new Promise((resolve, reject) =>
            this.hashFile(uppyFile.data).then( hash => {
                uppyFile.hash = hash
                resolve(uppyFile)
            })
        )
        return _(promise)
    }

    addUploadDataToUppyFile = (uppyFile) => {
        const uppy = this.uppy
        const promise = new Promise((resolve, reject) => {
            createUpload(uppyFile.hash).then( data => {
                if (data.completed) {
                    uppy.removeFile(uppyFile.id)
                    // TODO: It would be nice if we could mark these as complete somehow so they show up in the UI as having been uploaded,
                    //       but the uploader that runs in the next step (from the S3 plugin) does not check the file state before it starts
                    //       the upload.  I think we'd need to make our own UI and handle this when adding the file, or our own S3 uploader
                    //       plugin that can ignore files marked as already uploaded.  The line below does not work at the moment.
                    // uppy.setFileState(uppyFile.id, { progress: { uploadStarted: true, uploadComplete: true } })
                }
                resolve(uppyFile)
            })
        })
        return _(promise)
    }

    hashFile = async (file) => {
        const chunkSize = 64 * 1024 * 1024
        const fileReader = new FileReader()
        const sha1 = await createSHA1()
        sha1.init()

        function hashChunk(chunk) {
            return new Promise((resolve, reject) => {
                fileReader.onload = async (e) => {
                    const slice = new Uint8Array(e.target.result)
                    sha1.update(slice)
                    resolve()
                }
                fileReader.readAsArrayBuffer(chunk)
            })
        }

        const chunkCount = Math.floor(file.size / chunkSize);
        for (let i = 0; i <= chunkCount; i++) {
            const chunk = file.slice(
                chunkSize * i,
                Math.min(chunkSize * (i + 1), file.size)
            )
            await hashChunk(chunk)
        }
        return sha1.digest().toString()
    }

    install = () => {
        this.uppy.addPreProcessor(this.prepareUpload)
    }

    prepareUpload = async (fileIDs) => {
        fileIDs.forEach( fileID => {
            const file = this.uppy.getFile(fileID)
            this.uppy.emit('preprocess-progress', file, {
                mode: 'indeterminate',
                message: 'checking for already uploaded files'
            })
        })

        await _(fileIDs).map(this.uppy.getFile.bind(this.uppy))
            .map(this.addHashToUppyFile)
            .merge()
            .map(this.addUploadDataToUppyFile)
            .parallel(4)
            .collect()
            .toPromise(Promise)

        fileIDs.forEach((fileID) => {
            const file = this.uppy.getFile(fileID)
            this.uppy.emit('preprocess-complete', file)
        })
    }

    uninstall = () => {
        this.uppy.removePreProcessor(this.prepareUpload)
    }
}

export default class S3Uploader {

    constructor() {
        this.uppy = new Uppy({
            restrictions: { allowedFileTypes: [ 'audio/*' ] }
        })
            .use(HashPlugin)
            .use(AwsS3, {
                abortMultipartUpload: async (file, { uploadId, key, signal }) => abortUpload(uploadId, key, signal),
                completeMultipartUpload: async (file, { uploadId, key, parts, signal }) => completeUpload(uploadId, key, parts, signal),
                createMultipartUpload: async (file, { signal }) => startMultipartUpload(file.hash, signal),
                getUploadParameters: async (file, { signal }) => startUpload(file.hash, signal),
                listParts: async (file, { uploadId, signal}) => getUploadParts(uploadId, file.hash, signal),
                shouldUseMultipart: (file) => file.size > 100 * 2 ** 20,
                signPart: async (file, { uploadId, partNumber, signal }) => signUploadPart(uploadId, file.hash, partNumber, signal)
            })
        this.completed = []
    }
}
