import { useEffect, useState } from 'react'
import { GravatarQuickEditorCore } from '@gravatar-com/quick-editor'
import EditText from '../ui/EditText'
import Rooms from './Rooms'
import logout from '../auth/logout'
import { useLazyQuery, useMutation } from '@apollo/client'
import UsernameAvailable from '../graphql/queries/UsernameAvailable'
import UpdateUser from '../graphql/queries/UpdateUser'
import ProfileAvatar from './ProfileAvatar'
import useCurrentUser from '../hooks/useCurrentUser'
import { sizeFor } from '../util/text'

export default function Profile() {

    const user = useCurrentUser()
    const originalUsername = user?.username
    const [editing, setEditing] = useState('')
    const [username, setUsername] = useState(originalUsername ?? '')
    const [queryUsername, { data: queryUsernameData, error: queryUsernameError }] = useLazyQuery(UsernameAvailable, {
        fetchPolicy: 'cache-and-network',
        variables: { username }
    })
    const [updateUser, { data: updateUserData, error: updateUserError }] = useMutation(UpdateUser)

    useEffect(() => setUsername(originalUsername ?? ''), [originalUsername])

    function changeFocus(field) {
        setEditing(field)
    }

    let usernameError
    if ((queryUsernameData && !queryUsernameData.usernameAvailable) ||
        (updateUserError && updateUserError.graphQLErrors?.find( error => error.extensions?.code === 'USERNAME_EXISTS' ))) {
        usernameError = 'That user name is not available.'
    }

    async function updateAvatarTimestamp() {
        // TODO: create a mutation to add a cache-busting timestamp for avatar URLs
    }

    async function saveUsername() {
        if (!username || username === originalUsername) return
        try {
            await updateUser({ variables: { updates: { username } } })
        } catch (error) {
            setUsername(originalUsername)
        }
    }

    function updateAvatar() {
        const quickEditorCore = new GravatarQuickEditorCore( {
            email: user.email,
            scope: [ 'avatars' ],
            onProfileUpdated: () => updateAvatarTimestamp(),
            onOpened: () => console.log('Avatar editor opened')
        })
        quickEditorCore.open()
    }

    function usernameChanged(e) {
        const name = e.target.value
        if (name !== username) {
            setUsername(name)
            if (name !== originalUsername)
                queryUsername({ variables: { username: name } })
        }
    }

    return (
        <div className="ProfilePage">
            <div className="ProfileGrid">
                <div className="GridLabel" style={{ gridArea: 'emailLabel' }} onClick={() => changeFocus('')}>Email</div>
                <div className="GridText" style={{ gridArea: 'email' }}>{ user?.email }</div>
                <div className="GridLabel" style={{ gridArea: 'usernameLabel' }} onClick={() => changeFocus('')}>User Name</div>
                <div className="GridText" style={{ gridArea: 'username' }}>
                    <EditText
                        text={ username }
                        onClick={() => changeFocus('username')}
                        onBlur={() => saveUsername()}
                        updateText={usernameChanged}
                        editEnabled={editing === 'username'}
                        error={usernameError}
                    />
                </div>
                <div className="GridLabel" style={{ gridArea: 'avatarLabel' }} onClick={() => changeFocus('')}>Avatar</div>
                <div className="GridText" style={{ gridArea: 'avatar', display: 'flex' }}>
                    <ProfileAvatar user={user} />
                    <button onClick={updateAvatar} style={{ marginLeft: '10px' }}>Update with Gravatar</button>
                </div>
                <div className="GridLabel" style={{ gridArea: 'storageLabel' }} onClick={() => changeFocus('')}>Storage Used</div>
                <div className="GridText" style={{ gridArea: 'storage' }}>{ sizeFor(user?.storage ?? 0) }</div>
            </div>
            <div className="ProfileRooms">
                <div className="ProfileRoomsHeader">Rooms</div>
                <Rooms />
            </div>
            <div className="ProfileFooter">
                <button className="Button" onClick={logout}>Logout</button>
            </div>
        </div>
    )
}
