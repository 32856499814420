import React from 'react'
import ReactDOM from 'react-dom/client'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { linkChain } from './graphql/links/chain'
import tunistryCache from './graphql/TunistryCache'

const client = new ApolloClient({
    link: linkChain,
    cache: tunistryCache,
    connectToDevTools: true
})
global.apolloClient = client

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
