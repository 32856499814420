import { useEffect, useState } from 'react'
import { useAppDispatch } from '../../AppContext'
import { Actions } from '../../AppReducer'
import Spinner from '../../ui/Spinner'
import EditText from '../../ui/EditText'
import PeopleView from './PeopleView'
import { emailIsValid } from '../../util/validation'
import { useMutation, useQuery } from '@apollo/client'
import CurrentUser from '../../graphql/queries/CurrentUser'
import AddAdmin from '../../graphql/queries/AddAdmin'
import RemoveAdmin from '../../graphql/queries/RemoveAdmin'
import RemoveMember from '../../graphql/queries/RemoveMember'
import ResendInvite from '../../graphql/queries/ResendInvite'
import UpdateRoom from '../../graphql/queries/UpdateRoom'
import InviteByEmail from '../../graphql/queries/InviteByEmail'
import Room from '../../graphql/queries/Room'

export default function RoomDetails(props) {

    const dispatch = useAppDispatch()
    const roomId = props.room
    const { data: userData } = useQuery(CurrentUser)
    const { loading: roomDataLoading, data: roomData } = useQuery(Room, { variables: { roomId } })
    const user = userData?.user
    const room = roomData?.room
    const admins = room?.admins
    const members = room?.members
    const owner = admins?.find( admin => admin.id === room?.owner.id )
    const userIsOwner = owner?.id === user?.id
    const userIsAdmin = admins?.find( admin => admin.id === user.id )

    const [addAdminMutation, { loading: addAdminLoading }] = useMutation(AddAdmin)
    const [removeAdminMutation, { loading: removeAdminLoading }] = useMutation(RemoveAdmin)
    const [removeMemberMutation, { loading: removeMemberLoading }] = useMutation(RemoveMember)
    const [resendInviteMutation, { loading: resendInviteLoading }] = useMutation(ResendInvite)
    const [updateRoomMutation, { loading: updateRoomLoading }] = useMutation(UpdateRoom)
    const [inviteByEmailMutation, { loading: inviteByEmailLoading }] = useMutation(InviteByEmail)

    const [name, setName] = useState()
    const [topic, setTopic] = useState()
    const [spinning, setSpinning] = useState()
    const [editing, setEditing] = useState()
    const [inviteEmail, setInviteEmail] = useState('')
    const [inviteMessage, setInviteMessage] = useState('')
    const [inviteError, setInviteError] = useState('')
    const [addAdminUsername, setAddAdminUsername] = useState('')

    useEffect(() => {
        setName(room?.name)
        setTopic(room?.topic)
        setSpinning(room?.spinning)
    }, [room])

    async function addAdmin() {
        if (room?.admins.find( a => a.username === addAdminUsername )) {
            dispatch(Actions.showModal({ action: 'alert', text: 'That user is already an admin.' }))
            return
        }
        const member = room?.members.find( m => m.username === addAdminUsername )
        if (member) {
            await addAdminMutation({ variables: { roomId, adminId: member.id } })
        } else {
            dispatch(Actions.showModal({ action: 'alert', text: 'There is no room member with that username.' }))
        }
    }

    function removeAdmin(person) {
        if (user.id !== owner.id) return
        if (person.id === owner.id) {
            dispatch(Actions.showModal({
                action: 'alert',
                text: 'You are the owner of this room.  Owners always have admin privileges.'
            }))
        } else {
            dispatch(Actions.showModal({
                action: 'confirmation',
                text: `Are you sure you want to remove admin privileges for ${person.username}?  You will lose access to all of the music they have contributed to this room.`,
                confirmText: 'Remove Admin and their music',
                confirmAction: async () => {
                    await removeAdminMutation({ variables: { roomId, adminId: person.id } })
                }
            }))
        }
    }

    function removeMember(person) {
        console.log(`remove member => ${JSON.stringify(person)}`)
        if (admins.find( admin => admin.id === person.id )) {
            console.log(`can't remove admin`)
            const modalParams = {
                action: 'alert',
                text: 'This person is an admin.  In order to remove them the owner of the room will need to revoke their admin status.',
                okText: 'OK'
            }
            dispatch(Actions.showModal(modalParams))
        } else {
            const modalParams = {
                action: 'confirmation',
                text: `Are you sure you want to remove ${person.username}?`,
                confirmText: 'Remove Member',
                confirmAction: async () => {
                    await removeMemberMutation({ variables: { roomId, memberId: person.id } })
                    dispatch(Actions.dismissMainModal())
                }
            }
            dispatch(Actions.showModal(modalParams))
        }
    }

    async function resendEmailInvite(index) {
        setInviteMessage('')
        setInviteError('')
        const id = room?.invites[index].id
        try {
            await resendInviteMutation({ variables: { inviteId: id } })
            setInviteMessage('Invitation re-sent!')
        } catch (error) {
            setInviteError(error.message)
        }
    }

    async function saveField(field, value) {
        if (room?.[field] !== value) {
            await updateRoomMutation({ variables: { roomId, updates: { [field]: value  } } })
        }
    }

    async function sendEmailInvite() {
        setInviteMessage('')
        setInviteError('')
        try {
            await inviteByEmailMutation({ variables: { roomId, email: inviteEmail } })
            setInviteMessage('Invitation sent!')
        } catch (error) {
            setInviteError(error.message)
        }
    }

    function tryToEdit(field) {
        if (field === 'admins' && userIsOwner) {
            setEditing('admins')
        } else if (field === 'addAdmin' && userIsOwner) {
            setEditing('addAdmin')
        } else if (field !== 'admins' && userIsAdmin) {
            setEditing(field)
        }
    }

    function updateSpinning(text) {
        const newSpinning = text.replaceAll(/[^a-zA-Z0-9_ -]/g, '')
        setSpinning(newSpinning)
    }

    let invitationsSection
    if (userIsAdmin) {
        const invitations = room?.invites?.map( (invite, index) => [
            <div key={`ea-${index}`} style={{ gridRow: index, gridColumn: 1 }}>{ invite.email }</div>,
            <div key={`rb-${index}`} style={{ gridRow: index, gridColumn: 2 }}>
                <button disabled={resendInviteLoading} onClick={() => resendEmailInvite(index)}>Resend</button>
                { resendInviteLoading ? <Spinner /> : null }
            </div>
        ])
        invitationsSection = [
            <div key={1} className="GridLabel" style={{ gridArea: 'invitesLabel' }}>Invitations</div>,
            <div key={2} className="GridText" style={{ gridArea: 'invites' }}>
                <div className="RoomInvitations">
                    <div className="ActiveInvites">
                        <div className="InvitationsActiveGrid">
                            { invitations }
                        </div>
                    </div>
                    <div className="InviteByEmail">
                        <input value={inviteEmail} placeholder="Email address" onChange={(e) => setInviteEmail(e.target.value)} />
                        <button className="InviteByEmailButton" disabled={inviteByEmailLoading || !emailIsValid(inviteEmail)} onClick={sendEmailInvite}>Send Invite</button>
                        { inviteByEmailLoading ? <Spinner/> : null }
                    </div>
                    <div className="InviteMessage">{ inviteMessage }</div>
                    <div className="InviteError">{ inviteError }</div>
                </div>
            </div>
        ]
    }

    let addAdminSection
    if (userIsOwner) {
        addAdminSection = (
            <div className="RoomAddAdmin">
                <input value={addAdminUsername} placeholder="Username" onClick={() => tryToEdit('addAdmin')} onChange={(e) => setAddAdminUsername(e.target.value)} />
                <button className="AddAdminButton" disabled={addAdminLoading} onClick={addAdmin}>Add Admin</button>
                { addAdminLoading ? <Spinner/> : null }
            </div>
        )
    }

    if (roomDataLoading) return <div><Spinner/></div>

    return (
        <div>
            <div className="RoomDetailsGrid">
                <div className="GridLabel" style={{ gridArea: "nameLabel" }}>Name</div>
                <div className="GridText" style={{ gridArea: "name" }}>
                    <EditText
                        editEnabled={editing === 'name'}
                        text={name}
                        onBlur={() => saveField('name', name)}
                        updateText={(e) => setName(e.target.value)}
                        onClick={() => tryToEdit('name')}
                    />
                </div>
                <div className="GridLabel" style={{ gridArea: "topicLabel" }}>Topic</div>
                <div className="GridText" style={{ gridArea: "topic" }}>
                    <EditText
                        editEnabled={editing === 'topic'}
                        text={topic}
                        onBlur={() => saveField('topic', topic)}
                        updateText={(e) => setTopic(e.target.value)}
                        onClick={() => tryToEdit('topic')}
                    />
                </div>
                <div className="GridLabel" style={{ gridArea: "spinningLabel" }}>Spinning</div>
                <div className="GridText" style={{ gridArea: "spinning" }}>
                    <EditText
                        editEnabled={editing === 'spinning'}
                        text={spinning}
                        onBlur={() => saveField('spinning', spinning)}
                        updateText={(e) => updateSpinning(e.target.value)}
                        onClick={() => tryToEdit('spinning')}
                    />
                </div>
                <div className="GridLabel" style={{ gridArea: "ownerLabel" }}>Owner</div>
                <div className="GridText" style={{ gridArea: "owner" }}>{ owner?.username }</div>
                <div className="GridLabel" style={{ gridArea: "adminsLabel" }}>Admins</div>
                <div className="GridText" style={{ gridArea: "admins"}}>
                    <div>
                    <PeopleView
                        people={admins}
                        editing={editing === 'admins'}
                        onClick={() => tryToEdit('admins')}
                        onRemovePerson={removeAdmin}
                    />
                    { addAdminSection }
                    </div>
                </div>
                <div className="GridLabel" style={{ gridArea: 'membersLabel' }}>Members</div>
                <div className="GridText" style={{ gridArea: 'members' }}>
                    <PeopleView
                        people={members}
                        editing={editing === 'members'}
                        onClick={() => tryToEdit('members')}
                        onRemovePerson={removeMember}
                    />
                </div>
                { invitationsSection }
            </div>
        </div>
    )
}
