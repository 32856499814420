import Queue from './Queue'
import RoomList from './RoomList'
import Chat from './chat/Chat'
import ListenControls from './ListenControls'
import { Actions } from '../AppReducer'
import { useAppDispatch } from '../AppContext'
import useCurrentRoom from '../hooks/useCurrentRoom'
import { useState } from 'react'

export default function Listen() {

    const room = useCurrentRoom()
    const dispatch = useAppDispatch()
    const [ showRoomList, setShowRoomList ] = useState(false)
    const [ showQueue, setShowQueue ] = useState(false)

    function toggleQueue() {
        if (showRoomList) { setShowRoomList(false) }
        setShowQueue(!showQueue)
    }

    function toggleRoomList() {
        if (showQueue) { setShowQueue(false) }
        setShowRoomList(!showRoomList)
    }

    function showAddMusic() {
        dispatch(Actions.showSheet({ action: 'showAddMusic', data: room.id }))
    }

    function showQueueMusic() {
        dispatch(Actions.showSheet({ action: 'showQueueMusic', data: room.id }))
    }

    function showRoomDetails() {
        dispatch(Actions.showSheet({ action: 'showRoomDetails', data: room.id }))
    }


    return (
        <div className="ListenPage">
            <div className="ListenHeader">
                <div className="ListenHeaderText">
                    <span className="ListenHeaderName">{ room?.name }</span>
                    <span className="ListenHeaderTopic">{ room?.topic }</span>
                </div>
                <ListenControls
                    toggleQueue={toggleQueue}
                    queueActive={showQueue}
                    toggleRoomList={toggleRoomList}
                    roomsActive={showRoomList}
                    showQueueMusic={showQueueMusic}
                    showRoomDetails={showRoomDetails}
                    showAddMusic={showAddMusic}
                />
            </div>
            { showRoomList ? <RoomList toggleRoomList={toggleRoomList}/> : null }
            { showQueue ? <Queue toggleQueue={toggleQueue}/> : null }
            <Chat />
        </div>
    )
}
