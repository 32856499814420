import { Actions } from '../AppReducer'
import { useAppDispatch } from '../AppContext'
import { useMutation } from '@apollo/client'
import AddRequest from '../graphql/queries/AddRequest'

export default function useQueueTrack(roomId) {

    const dispatch = useAppDispatch()
    const [ addRequestMutation ] = useMutation(AddRequest)

    async function queueTrack(track, silent) {
        try {
            await addRequestMutation({ variables: { roomId: roomId, trackId: track.id } })
            if (!silent)
                dispatch(Actions.showToast({content: <span>Added <i>{track.title}</i> to the queue.</span>}))
        } catch (error) {
            console.error(`failed to queue track`, error)
        }
    }

    return queueTrack
}
